import { useState } from 'react'
import SignInEmailPassword from './SignInEmailPassword'
import SignInPasswordReset from './SignInPasswordReset'

const UnauthenticatedApp = (): JSX.Element => {
  const [method, setMethod] = useState('email')

  const passwordReset = (): void => {
    setMethod('passwordReset')
  }

  const signInEmail = (): void => {
    setMethod('email')
  }

  if (method === 'email') {
    return <SignInEmailPassword passwordReset={passwordReset} />
  } else if (method === 'passwordReset') {
    return <SignInPasswordReset signInEmail={signInEmail} />
  }
  return <div />
}

export default UnauthenticatedApp
