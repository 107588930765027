import Head from 'next/head'

const Meta = (): JSX.Element => (
  <Head>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charSet="utf-8" />
    <meta name="description" content="Ellingson Pumps" />
    <link rel="icon" type="image/png" sizes="96x96" href="logo.png" />
    <title>Ellingson Pumps</title>
  </Head>
)

export default Meta
