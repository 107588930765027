import { AlertStatusApiResponse } from '@/types/ApiResponse'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import useAxios from 'axios-hooks'
import { useRouter } from 'next/router'
import { Fragment, useState } from 'react'

interface AlertsProps {
  open: boolean
  setOpen: (open: boolean) => void
}

export default function Alerts({ open, setOpen }: AlertsProps) {
  const router = useRouter()
  const [selectedAlertId, setSelectedAlertId] = useState<string | null>(null)
  const [
    { data: alertData, loading: stationLoading, error: stationDataError },
    refetch,
  ] = useAxios<AlertStatusApiResponse>(
    `${process.env.NEXT_PUBLIC_PUMPS_BACKEND_URL}/alerts/user`
  )
  const [{ data: ackData, loading: ackLoading, error: ackError }, executeAck] =
    useAxios(
      {
        method: 'POST',
      },
      { manual: true }
    )

  if (stationLoading) return <div>Loading...</div>
  if (!alertData) return <div>No data</div>
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          Alerts
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <div className="mt-8 flow-root">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                              <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                    >
                                      Alert
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                      Station
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                      Time
                                    </th>
                                    <th
                                      scope="col"
                                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                    >
                                      <span className="sr-only">
                                        Acknowledge
                                      </span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                  {alertData.data.map((alert) => (
                                    <tr key={alert.id}>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {alert.name}
                                      </td>
                                      <button
                                        onClick={async () => {
                                          await router.push(
                                            `${alert.edge_node_id}`
                                          )
                                          setOpen(false)
                                        }}
                                      >
                                        <td className="whitespace-nowrap px-3 py-4 text-left text-sm text-gray-500">
                                          <div className="ml-4">
                                            <div className="font-medium text-gray-900">
                                              {alert.pump_station_name}
                                            </div>
                                            <div className="text-gray-500">
                                              {new Date(
                                                alert.alerted_at
                                              ).toLocaleString()}
                                            </div>
                                          </div>
                                        </td>
                                      </button>
                                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                        <button
                                          onClick={async () => {
                                            setSelectedAlertId(alert.id)
                                            await executeAck({
                                              url: `${process.env.NEXT_PUBLIC_PUMPS_BACKEND_URL}/alerts/status/${alert.id}/acknowledge`,
                                            })
                                            await refetch()
                                          }}
                                          className=" text-red-500 hover:text-indigo-900 w-full"
                                        >
                                          Acknowledge
                                          <span className="sr-only"></span>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
