import LogRocket from 'logrocket'
import { ReactNode, useEffect } from 'react'
import { useUser } from '../context/userContext'
import AuthenticatedApp from './AuthenticatedApp'
import OverlayLoading from './OverlayLoading'
import UnauthenticatedApp from './UnauthenticatedApp'

interface Props {
  children: ReactNode
}

function Page({ children }: Props): JSX.Element {
  const { user, loadingUser } = useUser()
  useEffect(() => {
    LogRocket.init('drlk3k/pumps-app')
  }, [])
  if (loadingUser) return <OverlayLoading />
  if (user) return <AuthenticatedApp>{children}</AuthenticatedApp>
  return <UnauthenticatedApp />
}

export default Page
