import axios from 'axios'
import { onIdTokenChanged } from 'firebase/auth'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { fbAuth } from '../lib/firebase'
import { getInitials } from '../lib/stringUtils'

export interface User {
  uid: string
  email: string | null
  displayName: string | null
  initials: string | null
  photoURL: string | null
  isEmployee: boolean | null
  hasAlertsAccess: boolean | null
}

export interface UserContext {
  user: User | null
  setUser: (user: User | null) => void
  loadingUser: boolean
}

interface Props {
  children: ReactNode
}

export const UserContext = createContext<UserContext | undefined>(undefined)

export const alertsUsers: string[] = [
  '9zR3BNNgotT9uXvTcX5AwYVTm252',
  'HvmlVE6DKNdhyAyggtXUwGXT4FH2',
]

export default function UserContextComp({ children }: Props): JSX.Element {
  const [user, setUser] = useState<User | null>(null)
  const [loadingUser, setLoadingUser] = useState(true) // Helpful, to update the UI accordingly.

  useEffect(() => {
    // Listen authenticated user
    const unsubscriber = onIdTokenChanged(fbAuth, async (user) => {
      try {
        if (user) {
          // User is signed in.
          const { uid, displayName, email, photoURL } = user
          const initials = `${displayName ? getInitials(displayName) : 'NA'}`
          const authToken = await user.getIdTokenResult()
          axios.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${authToken.token}`
          axios.interceptors.response.use(
            (response) => response,
            async (error) => {
              if (axios.isAxiosError(error) && error.response?.status === 401) {
                const authToken = await user.getIdTokenResult()
                axios.defaults.headers.common[
                  'Authorization'
                ] = `Bearer ${authToken.token}`
              }
              return Promise.reject(error)
            }
          )
          const isEmployee =
            authToken.claims.isEmployee && authToken.claims.isEmployee
              ? true
              : false
          const hasAlertsAccess = isEmployee || alertsUsers.includes(uid)
          // You could also look for the user doc in your Firestore (if you have one):
          // const userDoc = await firebase.firestore().doc(`users/${uid}`).get()
          setUser({
            uid,
            displayName,
            email,
            photoURL,
            initials,
            isEmployee,
            hasAlertsAccess,
          })
        } else setUser(null)
      } catch (error) {
        // Most probably a connection error. Handle appropriately.
        console.log(error)
      } finally {
        setLoadingUser(false)
      }
    })

    // Unsubscribe auth listener on unmount
    return () => unsubscriber()
  }, [])

  return (
    <UserContext.Provider value={{ user, setUser, loadingUser }}>
      {children}
    </UserContext.Provider>
  )
}

// Custom hook that shorthands the context!
export const useUser = (): UserContext => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUser must be used within a UserContextProvider')
  }
  return context
}
