import * as Sentry from '@sentry/nextjs'
import LogRocket from 'logrocket'
import { ReactNode, useEffect } from 'react'
import { useUser } from '../context/userContext'
import Header from './Header'

interface Props {
  children: ReactNode
}

const AuthenticatedApp = ({ children }: Props): JSX.Element => {
  const { user } = useUser()
  useEffect(() => {
    if (user) {
      Sentry.setUser({ email: user.email ? user.email : undefined })
      LogRocket.identify(user.uid, {
        name: user.displayName ? user.displayName : 'Unknown',
        email: user.email ? user.email : 'unknown@unknown.com',
      })
      LogRocket.getSessionURL((sessionURL) => {
        Sentry.configureScope((scope) => {
          scope.setExtra('sessionURL', sessionURL)
        })
      })
    }
  }, [user])
  return (
    <div className="h-full">
      <Header />
      <div className="flex flex-col flex-1 overflow-y-auto h-full w-full">
        <main className="flex justify-center h-full w-full">{children}</main>
      </div>
    </div>
  )
}

export default AuthenticatedApp
