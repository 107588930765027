import { ErrorMessage } from '@hookform/error-message'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { requestPasswordReset } from '../lib/auth'

interface FormData {
  email: string
}

interface Props {
  signInEmail: () => void
}

const SignInPasswordReset = ({ signInEmail }: Props): JSX.Element => {
  const [processing, setProcessing] = useState(false)
  const [showApiError, setShowApiError] = useState(false)
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({
    criteriaMode: 'all',
    mode: 'all',
  })

  const submitSignInForm = async (formData: FormData): Promise<void> => {
    setProcessing(true)
    try {
      await requestPasswordReset(formData.email)
      signInEmail()
    } catch (err) {
      setShowApiError(true)
      setProcessing(false)
    }
    setProcessing(false)
  }

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-24 w-auto"
            src="/Ellingson_RGB_Black_Blue.png"
            alt="Ellingson Companies"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form
              className="space-y-6"
              action="#"
              method="POST"
              onSubmit={handleSubmit(submitSignInForm)}
            >
              {showApiError ? (
                <div
                  className="bg-red-600 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-3"
                  role="alert"
                >
                  Invalid email or password
                </div>
              ) : null}
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    {...register('email', {
                      required: 'The email field is required.',
                      pattern: {
                        value:
                          /^([\w!#$%&'*+\-/=?^`{|}~]+\.)*[\w!#$%&'*+\-/=?^`{|}~]+@((((([a-z0-9]{1}[a-z0-9-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(:\d{1,5})?)$/,
                        message: 'Please enter a valid email.',
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="email"
                    render={({ message }) => (
                      <p className="text-errorRed text-sm xl:pt-2">{message}</p>
                    )}
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Request Reset Email
                </button>
              </div>
            </form>
            <button
              onClick={() => signInEmail()}
              className="w-full flex justify-center py-2 px-4 mt-6 border rounded-md shadow-sm text-sm font-medium text-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignInPasswordReset
