import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import {
  collection,
  CollectionReference,
  DocumentData,
  getFirestore,
} from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'
import { DeviceConfig, Metric } from '../../types/Devices'
import { PumpStatus, StationDoc } from '../../types/PumpStations'

const config = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
}
export const fbApp = initializeApp(config)

export const fbAuth = getAuth(fbApp)

export const firestore = getFirestore(fbApp)

const createCollection = <T = DocumentData>(
  collectionName: string
): CollectionReference<T> => {
  return collection(firestore, collectionName) as CollectionReference<T>
}
export const metricCol = createCollection<Metric>('metrics')
export const deviceConfigCol = createCollection<DeviceConfig>('deviceConfigs')
export const pumpsCol = createCollection<PumpStatus>('pumps')
export const pumpStationsCol = createCollection<StationDoc>('pumpStations')

// Initialize Cloud Functions through Firebase
// firebase.functions().useEmulator('localhost', 5001);
export const fbFunctions = getFunctions(fbApp)
