import {
  getAuth,
  OAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth'
import { alertsUsers, User } from './../context/userContext'
import { getInitials } from './stringUtils'

const microsoft = new OAuthProvider('microsoft.com')
microsoft.setCustomParameters({
  tenant: '1153390d-a949-4208-8bdd-1d51260e2682',
})

export const loginWithMicrosoft = async (): Promise<User> => {
  const auth = getAuth()
  const result = await signInWithPopup(auth, microsoft)
  const initials = `${
    result.user.displayName ? getInitials(result.user.displayName) : 'NA'
  }`
  const authToken = await result.user.getIdTokenResult()
  const isEmployee =
    authToken.claims.isEmployee && authToken.claims.isEmployee ? true : false
  const hasAlertsAccess = isEmployee || alertsUsers.includes(result.user.uid)
  return {
    uid: result.user.uid,
    email: result.user.email,
    displayName: result.user.displayName,
    initials,
    photoURL: result.user.photoURL,
    isEmployee,
    hasAlertsAccess,
  }
}

export const loginWithEmail = async (
  email: string,
  password: string
): Promise<User> => {
  try {
    const auth = getAuth()
    const result = await signInWithEmailAndPassword(auth, email, password)
    const initials = `${
      result.user.displayName ? getInitials(result.user.displayName) : 'NA'
    }`
    const authToken = await result.user.getIdTokenResult()
    const isEmployee =
      authToken.claims.isEmployee && authToken.claims.isEmployee ? true : false
    const hasAlertsAccess = isEmployee || alertsUsers.includes(result.user.uid)
    return {
      uid: result.user.uid,
      email: result.user.email,
      displayName: result.user.displayName,
      initials,
      photoURL: result.user.photoURL,
      isEmployee,
      hasAlertsAccess,
    }
  } catch (err: any) {
    throw new Error(err)
  }
}

export const requestPasswordReset = async (email: string): Promise<void> => {
  try {
    const auth = getAuth()
    await sendPasswordResetEmail(auth, email)
  } catch (err: any) {
    throw new Error(err)
  }
}

export const logout = async (): Promise<void> => {
  const auth = getAuth()
  await auth.signOut()
}
