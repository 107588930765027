import { getApps, initializeApp } from 'firebase/app'
import 'mapbox-gl/dist/mapbox-gl.css'
import { AppProps } from 'next/dist/shared/lib/router/router'
import { useEffect } from 'react'
import 'react-phone-number-input/style.css'
import '../assets/css/global.css'
import Meta from '../components/Meta'
import OverlayLoading from '../components/OverlayLoading'
import Page from '../components/Page'
import UserProvider from '../context/userContext'

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
}

export default function App({ Component, pageProps }: AppProps): JSX.Element {
  useEffect(() => {
    if (!getApps().length) {
      initializeApp(firebaseConfig)
    }
  }, [])
  if (!getApps().length) {
    return <OverlayLoading />
  }
  return (
    <UserProvider>
      <Meta />
      <Page>
        <Component {...pageProps} />
      </Page>
    </UserProvider>
  )
}
